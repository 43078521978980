import React from 'react'
import { useParams } from 'react-router-dom';
import { Box, Text, SimpleGrid, Heading, useMediaQuery } from "@chakra-ui/react";
import YouTube from 'react-youtube';

interface Peliculas {
  [nombre: string]: {
    nombre: string;
    capitulos: {
      titulo: string;
      link: string;
      id: string;
    }[];
  };
}

interface ParametrosURL {
  nombre: string;
}

const PeliculaDetalle = () => {  
  const [isMobile, isTablet] = useMediaQuery([
    "(max-width: 768px)",
    "(max-width: 1024px)",
  ]);
  let gridColumn = 4;
  if (isMobile) {
    gridColumn = 1;
  } else if (isTablet) {
    gridColumn = 2;
  }
  const { nombre } = useParams<ParametrosURL>();

  /*
    Videos: https://www.youtube.com/@Conectatefilms/videos
  */
  const peliculas: Peliculas = {
    "la-traicion": {
      nombre: 'La traición',
      capitulos: [
        { titulo: "La traición - Capitulo 1", link: "https://www.youtube.com/watch?v=YLLN7eoLuzk", id:'YLLN7eoLuzk' },
        { titulo: "La traición - Capitulo 2", link: "https://www.youtube.com/watch?v=mEjAhppNlCA", id:'mEjAhppNlCA' },
        { titulo: "La traición - Capitulo 3", link: "https://www.youtube.com/watch?v=SKWtIqFxktI", id:'SKWtIqFxktI' },
        { titulo: "La traición - Capitulo 4", link: "https://www.youtube.com/watch?v=WwsKOMsitlI", id:'WwsKOMsitlI' }
      ]
    },
    "lacritas-del-barrio": {
      nombre: 'Lacritas del barrio',
      capitulos: [
        { titulo: "Lacritas del barrio Capitulo - 1",  link: "https://www.youtube.com/watch?v=iPvbz0HyYaQ", id:'iPvbz0HyYaQ' },
        { titulo: "Lacritas del barrio Capitulo - 2",  link: "https://www.youtube.com/watch?v=zto9HBFMOx8", id:'zto9HBFMOx8' },
        { titulo: "Lacritas del barrio Capitulo - 3",  link: "https://www.youtube.com/watch?v=zREaSj8ibCg", id:'zREaSj8ibCg' },
        { titulo: "Lacritas del barrio Capitulo - 4",  link: "https://www.youtube.com/watch?v=fGDGvvMwNm8", id:'WwsKOMsitlI' },
        { titulo: "Lacritas del barrio Capitulo - 5",  link: "https://www.youtube.com/watch?v=KYcLqQd8MEk", id:'KYcLqQd8MEk' },
        { titulo: "Lacritas del barrio Capitulo - 6",  link: "https://www.youtube.com/watch?v=RNGjm0M_OZI", id:'RNGjm0M_OZI' },
        { titulo: "Lacritas del barrio Capitulo - 7",  link: "https://www.youtube.com/watch?v=7zpUaKSIvAo", id:'7zpUaKSIvAo' },
        { titulo: "Lacritas del barrio Capitulo - 8",  link: "https://www.youtube.com/watch?v=mw5IrPHTGac", id:'mw5IrPHTGac' },
        { titulo: "Lacritas del barrio Capitulo - 9",  link: "https://www.youtube.com/watch?v=o3RoZZ-kJVU", id:'o3RoZZ-kJVU' },
        { titulo: "Lacritas del barrio Capitulo - 10", link: "https://www.youtube.com/watch?v=IZ9ImQsFj_w", id:'IZ9ImQsFj_w' },
      ]
    },
    "lacritas-del-barrio-2": {
      nombre: 'Lacritas del barrio temporada 2',
      capitulos: [
        { titulo: "La Lacritas del barrio 2 - Capitulo 1", link: "https://www.youtube.com/watch?v=D7k7bBzpY2c", id:'D7k7bBzpY2c' },
        { titulo: "La Lacritas del barrio 2 - Capitulo 2", link: "https://www.youtube.com/watch?v=JivdehVKE64", id:'JivdehVKE64' }        
      ]
    },
    "sed-de-venganza": {
      nombre: 'Sed de venganza',
      capitulos: [
        { titulo: "Sed de venganza - Capitulo 1", link: "https://www.youtube.com/watch?v=8PfwVg3iBtY", id:'8PfwVg3iBtY' },
        { titulo: "Sed de venganza - Capitulo 2", link: "https://www.youtube.com/watch?v=grLZmYiFQSc", id:'grLZmYiFQSc' },
        { titulo: "Sed de venganza - Capitulo 3", link: "https://www.youtube.com/watch?v=ciuMAYwmhNU", id:'ciuMAYwmhNU' },
        { titulo: "Sed de venganza - Capitulo 4", link: "https://www.youtube.com/watch?v=pLmARpSSNzs", id:'pLmARpSSNzs' },
        { titulo: "Sed de venganza - Capitulo 5", link: "https://www.youtube.com/watch?v=8EEoC4rs_2Q", id:'8EEoC4rs_2Q' },
        { titulo: "Sed de venganza - Capitulo 6", link: "https://www.youtube.com/watch?v=9N2iW1D6wDY", id:'9N2iW1D6wDY' },
      ]
    },
  };

  const pelicula          = peliculas[nombre];
  const nombrePelicula    = peliculas[nombre]?.nombre;
  const capitulosPelicula = peliculas[nombre]?.capitulos;
  const normalStyles = {
    textDecoration: 'none',
  };
  return (    
    <Box bg="#170acd" color="gray.700" p={4} h={isMobile ? "auto" : "auto"} pb={'5%'}>
      <Box textAlign="center">
          <Text as="h1" color={'white'}>{nombrePelicula}</Text>
          {pelicula && (
            <SimpleGrid columns={gridColumn} spacing={4} mt={8}>
              {capitulosPelicula?.map((capitulo, index) => (
                <Box key={nombrePelicula + capitulo.id} bg="white" p={4} borderRadius="md">
                  <Heading as="h2" size="md" mb={2}>{capitulo.titulo}</Heading>
                  <YouTube videoId={capitulo.id} opts={{ width: '100%', height: '300px' }} />
                  <Text fontSize="sm"><a href={capitulo.link} target="_blank" rel="noopener noreferrer" style={normalStyles}>Ver capítulo en youtube</a></Text>
                </Box>
              ))}
            </SimpleGrid>
          )}
      </Box>
    </Box>    
  )
}


export default PeliculaDetalle;
