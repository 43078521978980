import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Image, useBreakpointValue } from '@chakra-ui/react';

// Importa tus imágenes aquí
import wilDirector1920 from './../images/wil_director_1920.jpg';
import lacritasDelBarrioImage from './../images/lacritas_del_barrio.jpg';
import lacritasDelBarrioTemp2Image from './../images/lacritas_del_barrio_temp_2.jpg';
import pactoDeSangreImage from './../images/pacto_de_sangre.jpg';

const Slide = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Determina la altura del slider
  const slideHeight = useBreakpointValue({ base: '250px', md: '610px' });

  // Define las imágenes
  const slides = [
    { src: wilDirector1920, alt: 'Wil Director' },
    { src: lacritasDelBarrioImage, alt: 'La traicion' },
    { src: lacritasDelBarrioTemp2Image, alt: 'La traicion' },
    { src: pactoDeSangreImage, alt: 'La traicion' },
  ];

  return (
    <Box h={slideHeight} w="auto">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Box key={index}>
            <Image src={slide.src} alt={slide.alt} h={slideHeight} w="100%" />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Slide;
