import React from "react";
import { Box, Text, SimpleGrid, Flex, Heading, useMediaQuery } from "@chakra-ui/react";
import { FiFilm, FiPlay, FiSettings, FiCamera, FiHome } from "react-icons/fi";

const AboutUs = () => {
  
  const data = [
    {
      title: "PRODUCTORA AUDIOVISUAL",
      description:
        "Somos una productora audiovisual especializada en cine y espectáculos. Creamos contenido propio y externo, desarrollando proyectos autónomos o participando en coproducciones nacionales e internacionales, prestamos servicios integrales de producción audiovisual a medida para compañía, locales nacionales y extranjeras.",
      icon: <FiFilm size={40} color="#1010FF" />,
    },
    {
      title: "ESPECTÁCULOS",
      description:
        "Somos especialistas en producción de espectáculos de música, danza y teatro. Diseñamos toda la producción de nuestros espectáculos, desde propuestas artísticas y técnica hasta la puesta en escena pasando por la producción ejecutiva.",
      icon: <FiPlay size={40} color="#1010FF" />,
    },
    {
      title: "SERVICIOS",
      description:
        "Prestamos servicios integrales de producción audiovisual a medida para compañías locales y extranjeras, siendo especialistas en el desarrollo de negocios de coproducción, producción ejecutiva.",
      icon: <FiSettings size={40} color="#1010FF" />,
    },
    {
      title: "CINE",
      description:
        "Somos especialistas en producción cinematográfica, cortometrajes, largometrajes de ficción cine guerrilla series y miniseries. Lideramos nuestros propios proyectos cinematográficos y colaboramos en coproducciones nacionales e internacionales.",
      icon: <FiCamera size={40} color="#1010FF" />,
    },
    {
      title: "FUNDACION NUNCA TE RINDAS",
      description:
        "La Fundación persigue fines de beneficencia, el mejoramiento de la calidad de vida y el bienestar común o de interés social dirigido a atender necesidades de niños y jóvenes para ayudarles a descubrir los talentos que poseen y potenciarlos para alcanzar metas de superación, colaborando con los padres de familia Brindar a niños y jóvenes, espacios para su normal desarrollo psicosocial, así como una atención personalizada y el refuerzo de su formación intelectual, humana, cultural y deporte a través de actividades de formación ética. formación humana y de valores; intensificación académica; formación cultural; actividades lúdicas e interacción con los padres.",
      icon: <FiHome  size={40} color="#1010FF" />,
    },
  ];

  const [isMobile, isTablet] = useMediaQuery([
    "(max-width: 768px)",
    "(max-width: 1024px)",
  ]);
  let gridColumn = 4;
  if (isMobile) {
    gridColumn = 1;
  } else if (isTablet) {
    gridColumn = 2;
  }

  return (
    <Box bgColor={'#170acd'} color="gray.700" p={4} h={isMobile ? "auto" : "auto"} pb={'5%'}>
      <Box mt="8%"  textAlign="center">
          <Text as="h1" color={'white'}>Sobre Nosotros</Text>
      </Box>
      <SimpleGrid columns={gridColumn} spacing={4} ml="6%" mr="6%">
        {data.map((item, index) => (
          <Box
            key={item.title}
            p={5}
            shadow="lg"
            borderWidth="1px"
            borderRadius="10px"
            borderColor="gray.300"
            overflow="hidden"
            bg="white"
            ml="4%"
          >
            <Flex justifyContent="center" mb={4} pt="5%">
              {item.icon}
            </Flex>
            <Heading fontSize="xl" textAlign="center">
              {item.title}
            </Heading>
            <Text mt={4} textAlign="left" ml="5%" mr="3%" color="#69707a">
              {item.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default AboutUs;
