import React from 'react';
import Slide  from './Slide';
import AboutUs  from './AboutUs';
import CardFilms  from './CardFilms';

import { ChakraProvider } from '@chakra-ui/react';
const Home = () => {
  return (
    <ChakraProvider>
      <Slide />
      <AboutUs />
      <CardFilms />
    </ChakraProvider>
  );
};

export default Home;
