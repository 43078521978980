// Importa las bibliotecas necesarias
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from './components/Layout';
import Home from './components/Home';
import PeliculaDetalle  from "./components/PeliculaDetalle";
//import Peliculas from "./components/Peliculas";

const App = () => {
  return (
    <Router>
      <Switch>
          <Layout>
            <Route path="/" exact component={Home} />
            {/*<Route path="/peliculas" exact component={Peliculas} />*/}
            <Route path="/pelicula/:nombre" exact component={PeliculaDetalle} />
          </Layout>
      </Switch>
    </Router>
  );
};

export default App;
