// Header.js
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./../App.css";
import {
  Box,
  Flex,
  Image,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import wilConectate from './../images/wilConectate.png';
import wilLinares from './../images/wilLinares.png';
const Header = () => {
    const [isMobile, isTablet] = useMediaQuery([
      "(max-width: 768px)",
      "(max-width: 1024px)",
    ]);
    let fontSizeTitle = "";
    if (isMobile || isTablet) {
      fontSizeTitle = "19px";
    }
  return (
    <Box bg="black" >
      <Flex alignItems="center" justifyContent="space-between">
        <Box>
          <Link to="/">
            <Image src={wilConectate} alt="Conectate Logo" h="70" w="70" />
          </Link>
        </Box>
        <Box>
          <Heading
            as="h1"
            size="xl"
            mt="3%"
            color="white"
            mb="5%"
            fontSize={fontSizeTitle}
          >
            La productora Conectate y Wil Linares FILMS
          </Heading>
        </Box>
        <Box>
          <Image src={wilLinares} alt="Linares Logo" h="100" w="100" />
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
