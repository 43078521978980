import React from 'react'
import {
  Box,
  Text,
  Image,
  Heading,
  SimpleGrid,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import laTraicionImage from './../images/la_traicion.jpg';
import lacritasDelBarrioImage from './../images/lacritas_del_barrio.jpg';
import lacritasDelBarrioTemp2Image from './../images/lacritas_del_barrio_temp_2.jpg';
//import pactoDeSangreImage from './../images/pacto_de_sangre.jpg';
import sedDeVenganzaImage from './../images/sed_de_venganza.jpg';
const CardFilms = () => {
  

  const [isMobile, isTablet] = useMediaQuery([
    "(max-width: 768px)",
    "(max-width: 1024px)",
  ]);
  let gridColumn = 4;
  if (isMobile) {
    gridColumn = 1;
  } else if (isTablet) {
    gridColumn = 2;
  }
  const movieCatalog = [
    {
      title: 'La Traición',
      image: laTraicionImage,
      description: 'Descripción de La Traición.',
      url: '/pelicula/la-traicion'
    },
    {
      title: 'Lacritas del Barrio',
      image: lacritasDelBarrioImage,
      description: 'Descripción de La Critas del Barrio.',
      url: '/pelicula/lacritas-del-barrio'
    },
    {
      title: 'Lacritas del Barrio Temp 2',
      image: lacritasDelBarrioTemp2Image,
      description: 'Descripción de La Critas del Barrio Temp 2.',
      url: '/pelicula/lacritas-del-barrio-2'
    },
    {
      title: 'Sed de Venganza',
      image: sedDeVenganzaImage,
      description: 'Descripción de Sed de Venganza.',
      url: '/pelicula/sed-de-venganza'
    },
    /*
    {
      title: 'Pacto de Sangre',
      image: pactoDeSangreImage,
      description: 'Descripción de Pacto de Sangre.',
      url: '/pelicula/pacto-de-sangre'
    },
    */
  ];
  const normalStyles = {
    textDecoration: 'none',
  };
  return (
    <>
      <Box  textAlign={'center'}>
          <Text as="h1" color={'black'}>Catálogo</Text>
      </Box>
      <SimpleGrid columns={gridColumn} spacing={4} ml="6%" mr="6%">
        {movieCatalog.map((movie, index) => (          
           <Link 
            to={movie?.url} 
            style={normalStyles}
            key={movie.title}
          >
            <Box
              shadow="lg"
              borderWidth="1px"
              borderRadius="10px"
              borderColor="gray.300"
              overflow="hidden"
              bg="white"
              ml="4%"
            >
              <Image src={movie.image} alt={movie.title} height={'300px' } width={'400px'}/>
              <Heading fontSize="xl" textAlign="center">
                {movie.title}
              </Heading>
            </Box>
          </Link>
        ))}
      </SimpleGrid>
    </>
  )
}


export default CardFilms;