// Footer.js
import React from 'react';
import {
  Box,
  Text,
  Link,
  Stack,
  Icon,
  Spacer,
} from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
const Footer = () => {
  return (
    <Box p={4} bg="#170acd" color="white" textAlign="center">
      <Stack spacing={2}>
        <Text>
          WHATSAPP:
          <Spacer />
          <Icon as={FaWhatsapp} />
          <Link href="https://wa.me/584123966784" color="white">+584123966784</Link>
          <Spacer />
          <Icon as={FaWhatsapp} />
          <Link href="https://wa.me/584242308384" color="white">+584242308384</Link>
          <Spacer />
          Derechos de autor © {new Date().getFullYear()} Wil Conectate.
          <Spacer />
          Todos los derechos reservados.
        </Text>
      </Stack>
    </Box>
  );
};

export default Footer;
