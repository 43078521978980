// Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Box } from '@chakra-ui/react'; 

const Layout = ({ children }: any) => {
    return (
        <Box>
            <Header />
            <Box>
                {children}
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;
